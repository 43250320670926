import React, { Suspense, useEffect, useState } from "react";
import {
  Button,
  Carousel,
  Col,
  Container,
  Row,
  Image,
  Spinner,
} from "react-bootstrap";
import { Splide } from "@splidejs/react-splide";

import CardInfo from "../../components/CardInfo";
import IconDivisor from "../../components/IconDivisor";
import RedirectLink from "../../components/RedirectLink";

import { REST_URL_IMAGES } from "../../config/env";
import BgDocumental from "../../components/BgDocumental";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import BreakLine from "../../components/BreakLine";
import { REST_URL } from "../../config/env";

import "./style.css";
import SkeletonCardInfo from "../../components/CardInfo/Loader";
import Popup from "../../components/Popup/Popup";

export default function Home() {
  const [configHome, setconfigHome] = useState([]);
  const [loading_configHome, setLoadingconfigHome] = useState(true);

  const [array_documentales, setArrayDocumentales] = useState([]);
  const [loading_documentales, setLoadingDocumentales] = useState(true);

  const [array_agenda, setArrayAgenda] = useState([]);
  const [loading_agenda, setLoadingAgenda] = useState(true);

  let [array_tipos, setArrayTipos] = useState([]);
  const [loading_tipos, setLoadingTipos] = useState(true);

  //academicos
  let [totalNumero, settotalNumero] = useState([0]);
  let [totaldelegado, settotaldelegado] = useState([0]);
  let [totalcorrespondientes, settotalcorrespondientes] = useState([0]);
  let [totalemeritos, settotalemeritos] = useState([0]);
  let [totalhonorios, settotalhonorios] = useState([0]);
  let [totalfallecidos, settotalfallecidos] = useState([0]);

  useEffect(() => {
    setLoadingDocumentales(true);
    setLoadingAgenda(true);
    setLoadingTipos(true);

    obtenerconfigHome();
    obtenerFondoDocumental();
    obtenerAgendaHome();
    obtenerCantidadAcademicos();

    fetch(`${REST_URL}/fetch-initial-data`)
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 200) {
          const { array_tipos } = data.body;
          setArrayTipos(array_tipos);
        } else {
          console.error(data.msg);
        }

        setLoadingTipos(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingAgenda(false);
        setLoadingTipos(false);
      });
  }, []);

  async function obtenerconfigHome() {
    await fetch(REST_URL + "/home", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((rawJson) => {
        console.log(rawJson);
        setconfigHome(rawJson);
        setLoadingconfigHome(false);
      });
  }

  async function obtenerFondoDocumental() {
    await fetch(REST_URL + "/fondoDocumental", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((rawJson) => {
        let FD_filtrado = [];
        let i = -1;

        rawJson.map((FD) => {
          if (FD.home === 1) {
            i++;
            return (FD_filtrado[i] = FD);
          }
          return 0;
        });
        console.log("fondo documental:" + FD_filtrado);

        setArrayDocumentales(FD_filtrado);
        console.log("ACA", FD_filtrado);
        setLoadingDocumentales(false);
      });
  }

  async function obtenerAgendaHome() {
    try {
      const response = await fetch(REST_URL + "/agenda/home", {
        mode: "cors",
        "retry-After": "3600",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
      }

      const rawJson = await response.json();

      console.log("agenda home:", rawJson);

      let AG_filtrado = [];
      let i = -1;

      rawJson.forEach((AG) => {
        if (AG.home === 1) {
          i++;
          AG_filtrado[i] = AG;
        }
      });

      const arrayOrdenado = [...AG_filtrado].sort((a, b) => {
        const fechaA = a.fechaPublicacion.split("/").reverse().join("/");
        const fechaB = b.fechaPublicacion.split("/").reverse().join("/");
        return new Date(fechaB) - new Date(fechaA);
      });

      setArrayAgenda(arrayOrdenado);
      setLoadingAgenda(false);
    } catch (error) {
      console.error("Error al obtener la agenda:", error);
      setLoadingAgenda(false);
    }
  }

  async function obtenerCantidadAcademicos() {
    await fetch(REST_URL + "/academicos", {
      mode: "cors",
      "retry-After": "3600",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((rawjson) => {
        rawjson.map((academico) => {
          if (academico.tipo === "Académicos de número") {
            settotalNumero((totalNumero) => parseInt(totalNumero) + 1);
          }
          if (academico.tipo === "Académicos delegados") {
            settotaldelegado((totaldelegado) => parseInt(totaldelegado) + 1);
          }
          if (academico.tipo === "Académicos correspondientes") {
            settotalcorrespondientes(
              (totaltotalcorrespondientes) =>
                parseInt(totaltotalcorrespondientes) + 1
            );
          }
          if (academico.tipo === "Académicos Eméritos") {
            settotalemeritos(
              (totaltotalemeritos) => parseInt(totaltotalemeritos) + 1
            );
          }
          if (academico.tipo === "Académicos delegados honorios") {
            settotalhonorios((totalhonorios) => parseInt(totalhonorios) + 1);
          }
          if (academico.tipo === "Académicos fallecidos") {
            settotalfallecidos(
              (totalfallecidos) => parseInt(totalfallecidos) + 1
            );
          }

          return 0;
        });
      });
  }

  const handleMouseOver = (id) => {
    let element = document.getElementById(`bg_documental_${id}`);

    if (element.style.display === "none") {
      element.style.display = "flex";
    } else {
      element.style.display = "none";
    }
  };

  function createCarousel() {
    return (
      <Carousel
        prevIcon={
          <span className="custom-icon-size carousel-control-prev-icon" />
        }
        nextIcon={
          <span className="custom-icon-size carousel-control-next-icon" />
        }
      >
        {configHome.txtImagen1 !== "" ? (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={
                "https://anba-admin.depcsuite.com/home_images/" +
                configHome.txtImagen1
              }
              alt="Anba.png"
            />
            <Carousel.Caption>
              <h3 className="text-uppercase mb-md-3">
                academia nacional de bellas artes
              </h3>
              <p className="mb-0 text-decr">
              La Academia contribuye al desarrollo de las Bellas Artes
              Artes Visuales | Arquitectura | Diseño y Urbanismo | Música
              Historia y Crítica del Arte | Acción Cultural                  
              </p>
            </Carousel.Caption>
            <div className="footer-caption position-absolute bottom-0 end-0 start-0 mx-0 text-center mb-5 mb-md-0 pb-2 pb-md-0 pb-lg-5">
              <HashLink smooth to="/academicos">
                <Button className="text-uppercase px-md-4 py-md-2 mb-md-5">
                  conoce a nuestro cuerpo académico
                </Button>
              </HashLink>
            </div>
          </Carousel.Item>
        ) : (
          <></>
        )}
        {configHome.txtImagen2 !== "" ? (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={
                "https://anba-admin.depcsuite.com/home_images/" +
                configHome.txtImagen2
              }
              alt="Anba.png"
            />
            <Carousel.Caption>
              <h3 className="text-uppercase mb-md-3">
                academia nacional de bellas artes
              </h3>
              <p className="mb-0 text-decr">
              La Academia contribuye al desarrollo de las Bellas Artes
              Artes Visuales | Arquitectura | Diseño y Urbanismo | Música
              Historia y Crítica del Arte | Acción Cultural                  
              </p>
            </Carousel.Caption>
            <div className="footer-caption position-absolute bottom-0 end-0 start-0 mx-0 text-center mb-5 mb-md-0 pb-2 pb-md-0 pb-lg-5">
              <HashLink smooth to="/academicos">
                <Button className="text-uppercase px-md-4 py-md-2 mb-md-5">
                  conoce a nuestro cuerpo académico
                </Button>
              </HashLink>
            </div>
          </Carousel.Item>
        ) : (
          <></>
        )}

        {configHome.txtImagen3 !== "" ? (
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={
                "https://anba-admin.depcsuite.com/home_images/" +
                configHome.txtImagen3
              }
              alt="Anba.png"
            />
            <Carousel.Caption>
              <h3 className="text-uppercase mb-md-3">
                academia nacional de bellas artes
              </h3>
              <p className="mb-0 text-decr">
              La Academia contribuye al desarrollo de las Bellas Artes
              Artes Visuales | Arquitectura | Diseño y Urbanismo | Música
              Historia y Crítica del Arte | Acción Cultural                  
              </p>
            </Carousel.Caption>
            <div className="footer-caption position-absolute bottom-0 end-0 start-0 mx-0 text-center mb-5 mb-md-0 pb-2 pb-md-0 pb-lg-5">
              <HashLink smooth to="/academicos">
                <Button className="text-uppercase px-md-4 py-md-2 mb-md-5">
                  conoce a nuestro cuerpo académico
                </Button>
              </HashLink>
            </div>
          </Carousel.Item>
        ) : (
          <></>
        )}
      </Carousel>
    );
  }

  return (
    <section id="home">
      <article style={{ minHeight: 600 }}>
        {loading_configHome ? <></> : <>{createCarousel()}</>}
      </article>
      <article>
        <Container className="pb-md-5">
          <Row className="my-4 py-2">
            <Col className="text-center">
              <h2 className="title-article">Agenda</h2>
              <p className="text-descr">
                La Academia organiza periódicamente concursos, exposiciones y
                conciertos;
                <br /> a la vez, concede diversas becas de estudio y de
                perfeccionamiento.
              </p>
              <div className="pb-md-3">
                <IconDivisor />
              </div>
            </Col>
          </Row>
          <Row
            className="pb-md-3 justify-content-center"
            style={{ minHeight: 500 }}
          >
            <Col md="10">
              {loading_agenda ? (
                <Col className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Col>
              ) : (
                <Splide
                  options={{
                    perPage: 3,
                    breakpoints: {
                      1024: {
                        perPage: 3,
                      },
                      767: {
                        perPage: 2,
                      },
                      640: {
                        perPage: 1,
                      },
                    },
                    gap: 25,
                    arrows: false,
                    pagination: true,
                    lazyLoad: "nearby",
                  }}
                >
                  {array_agenda.map((noticia) => {
                    return (
                      <CardInfo
                        key={noticia.id}
                        categoria={noticia.categoria}
                        titulo={noticia.titulo}
                        descr={noticia.descripcion}
                        img={
                          "https://anba-admin.depcsuite.com/agenda_imagenes/" +
                          noticia.imagen
                        }
                        linkTo={"/noticias/" + noticia.id + "?id=" + noticia.id}
                      />
                    );
                  })}
                </Splide>
              )}
            </Col>
          </Row>
        </Container>
      </article>
      <article>
        <Container fluid>
          <Row>
            <Col md="6" xl="4">
              <div className="px-md-4 mx-md-4 pt-4 pb-4 pt-md-5 mt-md-2 pb-md-3">
                <h2 className="title-article text-center mb-3 poppins-extrabold">
                  academia nacional de bellas artes
                </h2>
                <div className="pb-3 text-center">
                  <IconDivisor opacity={0.3} />
                </div>
                <p className="text-center mb-md-3 descr-article">
                  Su acervo cultural está compuesto por un importante fondo
                  patrimonial y obras de arte, de la que se conserva un conjunto
                  integrado por pinturas, esculturas, grabados, litografías,
                  dibujos y tapices, donados por sus autores, artistas
                  académicos y coleccionistas.
                </p>
                <div>
                  <RedirectLink
                    to={"/sobre-nosotros/#mesaDirectiva"}
                    text={"Mesa directiva 2022/24"}
                    fontSize={16}
                  />
                </div>
                <div className="my-md-1">
                  <RedirectLink
                    to={"/sobre-nosotros/#staff"}
                    text={"Equipo de trabajo (staff)"}
                    fontSize={16}
                  />
                </div>
                <div>
                  <RedirectLink
                    to={"/estatuto"}
                    text={"Estatuto y Reglamento Interno"}
                    fontSize={16}
                  />
                </div>
              </div>
            </Col>
            <Col
              md="6"
              xl="8"
              className="pe-md-0 position-relative"
              style={{ overflow: "hidden" }}
            >
              <video
                src={require("./../../assets/videos/VideoInstitucional.mp4")}
                controls
                className="w-100 h-100"
              />
              {/* <div className='position-absolute top-0 bottom-0 start-0 end-0 text-center d-flex flex-column justify-content-center'>
                <h6 className='text-uppercase m-0'>video presentación de la academia</h6>
                <ContainerIcon icon={faPlay} width={50} height={50} opacity={0.5} className='my-md-4 my-3' />
                <p className='m-0'>Breve introducción al contenido del video</p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </article>
      <article>
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col md="7">
              <HashLink
                to="/academicos?tipo=Académicos de número#top"
                className="text-decoration-none nav-link"
              >
                <h2 className="title-article text-center mb-0">
                  cuerpo académico
                </h2>
              </HashLink>
              <div className="py-3 text-center">
                <IconDivisor opacity={0.3} width={100} />
              </div>
              <p className="mb-0 mb-md-5 descr-article text-center">
                Para obtener más información sobre cada uno de los académicos de
                la ANBA seleccione el enlace que desee.
              </p>
            </Col>
            <Col xs="10">
              {loading_tipos === true ? (
                <p className="my-4 text-center">Cargando...</p>
              ) : (
                <Row md={3}>
                  <Link
                    class="FundacionLink"
                    to={"/academicos?tipo=Académicos de número"}
                    onClick={() => {
                      window.scroll(0, 0);
                      localStorage.removeItem("ultimoAcademico");
                    }}
                  >
                    <div className="containerEtiquetasAcademicos">
                      <img
                        src={require("../../assets/img/radiosAcademicos/AN.png")}
                        alt=""
                      />

                      {/* <CircleBlue title={"AN"} /> */}

                      <div className="etiquetasAcademicosText">
                        <h5>ACADÉMICOS DE NÚMERO</h5>
                        <p>{totalNumero} académicos</p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    class="FundacionLink"
                    to={"/academicos?tipo=Académicos delegados"}
                    onClick={() => {
                      window.scroll(0, 0);
                      localStorage.removeItem("ultimoAcademico");
                    }}
                  >
                    <div className="containerEtiquetasAcademicos">
                      <img
                        src={require("../../assets/img/radiosAcademicos/NAD.png")}
                        alt=""
                      />
                      {/* <CircleBlue title={"AD"} /> */}
                      <div className="etiquetasAcademicosText">
                        <h5>ACADÉMICOS DELEGADOS</h5>
                        <p>{totaldelegado} académicos</p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    class="FundacionLink"
                    to={"/academicos?tipo=Académicos correspondientes"}
                    onClick={() => {
                      window.scroll(0, 0);
                      localStorage.removeItem("ultimoAcademico");
                    }}
                  >
                    <div className="containerEtiquetasAcademicos">
                      <img
                        src={require("../../assets/img/radiosAcademicos/AC.png")}
                        alt=""
                      />
                      {/* <CircleBlue title={"AC"} /> */}
                      <div className="etiquetasAcademicosText">
                        <h5>ACADÉMICOS CORRESPONDIENTES</h5>
                        <p>{totalcorrespondientes} académicos</p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    class="FundacionLink"
                    to={"/academicos?tipo=Académicos Eméritos"}
                    onClick={() => {
                      window.scroll(0, 0);
                      localStorage.removeItem("ultimoAcademico");
                    }}
                  >
                    <div className="containerEtiquetasAcademicos">
                      <img
                        src={require("../../assets/img/radiosAcademicos/AE.png")}
                        alt=""
                      />
                      {/* <CircleBlue title={"AE"} /> */}
                      <div className="etiquetasAcademicosText">
                        <h5>ACADÉMICOS EMÉRITOS</h5>
                        <p>{totalemeritos} académicos</p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    class="FundacionLink"
                    to={"/academicos?tipo=Académicos delegados honorios"}
                    onClick={() => {
                      window.scroll(0, 0);
                      localStorage.removeItem("ultimoAcademico");
                    }}
                  >
                    <div className="containerEtiquetasAcademicos">
                      <img
                        src={require("../../assets/img/radiosAcademicos/AH.png")}
                        alt=""
                      />
                      {/* <CircleBlue title={"AH"} /> */}
                      <div className="etiquetasAcademicosText">
                        <h5>ACADÉMICOS HONORARIOS </h5>
                        <p>{totalhonorios} académicos</p>
                      </div>
                    </div>
                  </Link>

                  <Link
                    class="FundacionLink"
                    to={"/academicos?tipo=Académicos fallecidos"}
                    onClick={() => {
                      window.scroll(0, 0);
                      localStorage.removeItem("ultimoAcademico");
                    }}
                  >
                    <div className="containerEtiquetasAcademicos">
                      <img
                        src={require("../../assets/img/radiosAcademicos/AF.png")}
                        alt=""
                      />
                      {/* <CircleBlue title={"AF"} /> */}
                      <div className="etiquetasAcademicosText">
                        <h5>ACADÉMICOS FALLECIDOS</h5>
                        <p>{totalfallecidos} académicos</p>
                      </div>
                    </div>
                  </Link>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </article>
      <article>
        <Container fluid id="historia">
          <Row className="justify-content-end">
            <Col md="6" xl="5" className="me-xl-4 pe-xl-3 py-4 py-xl-5">
              <div className="pt-md-4">
                <h3 className="article-subtitle mb-1">
                  grandes hitos de nuestra historia
                </h3>
                <HashLink
                  to="/hitos#top"
                  className="text-decoration-none nav-link"
                >
                  <h2 className="title-article text-center mb-0">
                    historia de la academia
                  </h2>
                </HashLink>
                <div className="py-3 text-center">
                  <IconDivisor opacity={0.3} width={100} />
                </div>
                <p className="mb-md-5  text-center descr-article px-md-4 mx-md-2">
                  La Academia Nacional de Bellas Artes, fue creada por Decreto
                  del Poder Ejecutivo Nacional el 1 de julio de 1936 con un
                  grupo de intelectuales y personalidades de la cultura. De
                  acuerdo con el Decreto Nro. 4362 del Poder Ejecutivo Nacional
                  del 30 de noviembre de 1955, ha sido autorizada para funcionar
                  con carácter de persona jurídica por Decreto del Poder
                  Ejecutivo Nacional Nro. 11.636 del 25 de septiembre de 1957.
                </p>
                <div className="ps-md-4 ms-md-2">
                  <RedirectLink
                    to={"/hitos"}
                    text={"Ver la línea de tiempo"}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col md="6" className="pe-0 ps-0 ps-lg-3">
              <img
                className="w-100"
                src={require("../../assets/img/historia_academia.jpg")}
                alt="imagen_historia.png"
              />
            </Col>
          </Row>
        </Container>
      </article>
      <article>
        <Container fluid className="pt-5">
          <Row className="pt-lg-5 justify-content-center">
            <Col md="11" xl="12">
              <HashLink
                to="/fondo-patrimonial#top"
                className="text-decoration-none nav-link"
              >
                <h2 className="title-article text-center mb-0">
                  fondo patrimonial de la anba
                </h2>
              </HashLink>
              <div className="py-3 text-center">
                <IconDivisor opacity={0.3} width={100} />
              </div>
              <p className="mb-0 mb-md-3 descr-article text-center">
                La Academia Nacional de Bellas Artes cuenta con un acervo
                construido a través de los años, con el aporte de sus miembros y
                personalidades de la cultura,
                <br />
                que permanentemente realizan donaciones y legados de libros,
                obras de arte, archivos, fotografías, entre otros.
              </p>
            </Col>
          </Row>
          <Row>
            {loading_documentales ? (
              <>
                <p className="my-5 text-center">Cargando...</p>
              </>
            ) : (
              <Col className="px-0">
                <div
                  className="wrapper"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {array_documentales.map((documental) => {
                    if (documental.home === 1) {
                      return (
                        <Link
                          to={
                            "/fondo-patrimonial/" +
                            documental.id +
                            "?id=" +
                            documental.id
                          }
                          onClick={() => {
                            window.scroll(0, 0);
                          }}
                        >
                          <div
                            key={documental.id}
                            className="position-relative"
                            onMouseEnter={() => handleMouseOver(documental.id)}
                            onMouseLeave={() => handleMouseOver(documental.id)}
                          >
                            <Image
                              className="w-100"
                              src={`${REST_URL_IMAGES}/fondo_documental_imagenes/${documental.imagen}`}
                              alt={`${documental.imagen}`}
                            />
                            <BgDocumental
                              id={`bg_documental_${documental.id}`}
                              titulo={documental.titulo}
                              categoria={documental.categoria}
                            />
                          </div>
                        </Link>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </article>
      <article>
        <Container className="py-5">
          <Row className="pt-lg-5">
            <Col md="12">
              <HashLink
                to="/instituciones-relacionadas#top"
                className="text-decoration-none nav-link"
              >
                <h2 className="title-article text-center mb-0">
                  instituciones relacionadas
                </h2>
              </HashLink>
              <div className="py-3 text-center mb-5">
                <IconDivisor opacity={0.3} width={100} />
              </div>
            </Col>
          </Row>
          <Row className="instituciones d-md-none justify-content-center"></Row>
          <Row className="instituciones justify-content-md-center  d-md-flex pt-md-3 pt-lg-0">
            <Col className="px-md-3 col-lg-6 col-md-4 col-12">
              <Row className="px-md-2">
                <HashLink
                  to="/fundacion-trabucco#top"
                  className="FundacionLink"
                >
                  <Col xs="12" className="mb-md-3">
                    <img
                      className="w-100"
                      src={require("../../assets/img/Trabucco.png")}
                      alt="Trabucco.png"
                    />
                  </Col>
                  <Col className="align-self-end mt-3">
                    <h3>fundación trabucco</h3>
                    <p>
                      La Fundación Alberto J. Trabucco fue creado por la
                      Academia Nacional de Bellas Artes en 1991 con fondos
                      provenientes del legado que el distinguido artista,
                      Alberto Trabucco, donó a nuestra institución.
                    </p>
                  </Col>
                </HashLink>
              </Row>
            </Col>

            <Col className="px-md-3 col-lg-6 col-md-4 col-12">
              <a
                href="https://www.fundacionfjklemm.org/"
                target="blank"
                className="FundacionLink"
              >
                <Row className="px-md-2">
                  <Col xs="12" className="mb-md-3">
                    <img
                      className="w-100"
                      src={require("../../assets/img/KLEMM.png")}
                      alt="KLEMM.png"
                    />
                  </Col>
                  <Col className="align-self-end mt-3">
                    <h3 className="tituloFundacion">fundación klemm</h3>
                    <p>
                      Desde 1998, la Fundación Klemm cuenta con la designación
                      de la Academia Nacional de Bellas Artes como veedora
                      artística y administrativa de la Fundación Klemm.
                    </p>
                  </Col>
                </Row>
              </a>
            </Col>
          </Row>

          <Row className="pt-lg-5">
            <Col md="12">
              <h2 className="title-article text-center mb-0">
                TAMBIEN ENCONTRARÁS EN LA ANBA
              </h2>
              <div className="py-3 text-center mb-5">
                <IconDivisor opacity={0.3} width={100} />
              </div>
            </Col>
          </Row>

          <Row className="instituciones justify-content-md-center  d-md-flex pt-md-3 pt-lg-0">
            <Col className="px-md-3 col-lg-6 col-md-4 col-12">
              <Link
                to={"/publicaciones"}
                onClick={() => {
                  window.scroll(0, 0);
                }}
                className="FundacionLink"
              >
                <Row className="px-md-2">
                  <Col xs="12" className="mb-md-3">
                    <img
                      className="w-100"
                      src={require("../../assets/img/Publicaciones.png")}
                      alt="Publicaciones.png"
                    />
                  </Col>
                  <Col className="align-self-end mt-3">
                    <h3>PUBLICACIONES</h3>
                    <p>
                      Realizamos estudios e investigaciones divulgando sus
                      resultados.
                    </p>
                  </Col>
                </Row>
              </Link>
            </Col>

            <Col className="px-md-3 col-lg-6 col-md-4 col-12">
              <a
                href="http://186.23.210.29/"
                target="blank"
                className="FundacionLink"
              >
                <Row className="px-md-2">
                  <Col xs="12" className="mb-md-3">
                    <img
                      className="w-100"
                      src={require("../../assets/img/Biblioteca.png")}
                      alt="Biblioteca.png"
                    />
                  </Col>
                  <Col className="align-self-end mt-3">
                    <h3 className="tituloFundacion">
                      BIBLIOTECA HORACIO BUTLER
                    </h3>
                    <p>
                      La ANBA posee una Biblioteca especializada en Arte abierta
                      para la consulta.
                    </p>
                  </Col>
                </Row>
              </a>
            </Col>
          </Row>
        </Container>
      </article>

      {/* <Popup /> */}
    </section>
  );
}
